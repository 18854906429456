let en = {
    Application: "Application Scenarios",
    title1: "Low-Code Platform (LCDP)",
    content1: "SEOR-LCDP has adopted the self-developed unified protocol family and the customization of contracts for different blockchain systems. It currently supports the deployment of code-free contracts of the EVM compatible chain and the unified code access of some public chains, which can quickly solve the problem of unified interaction of multi-chain smart contracts. At the same time, it provides customized presentation method of Web3.0, as well as unified access in the form of cloud services, and unified deployment on the business chain.",
    title2: "Lightweight Oracle Network",
    content2: "SEOR Network combines lightweight Oracle network (LON) and conventional Oracle protocol, which can provide flexible data customization.It can not only provide accurate and reliable data on the chain for traditional enterprises and .0 developers, but also provide data support for breaking the barriers of the traditional Internet and the blockchain world.At the same time, it can also provide a unified general data protocol for off - chain data for different blockchain applications, such as DeFi, GameFi, SocialFi, Tools and other dApps.",
    title3: "Cross-Chain Bridge",
    content3: "As the asset and information exchange bridge of the blockchain system, with SEOR Network's data protocol family as the core, it provides a comprehensive cross- chain solution that is compatible with different assets and data protocols on each chain, allowing assets on different chains to support Cross - chain transfer and exchange.",
}

let zh = {
    Application: "Application Scenarios",
    title1: "Low-Code Platform (LCDP)",
    content1: "SEOR-LCDP has adopted the self-developed unified protocol family and the customization of contracts for different blockchain systems. It currently supports the deployment of code-free contracts of the EVM compatible chain and the unified code access of some public chains, which can quickly solve the problem of unified interaction of multi-chain smart contracts. At the same time, it provides customized presentation method of Web3.0, as well as unified access in the form of cloud services, and unified deployment on the business chain.",
    title2: "Lightweight Oracle Network",
    content2: "Project supported by Web3.0 Foundation Grants program Powered by Polkadot",
    title3: "Cross-chain bridge",
    content3: "As the asset and information exchange bridge of the blockchain system, with SEOR Network's data protocol family as the core, it provides a comprehensive cross- chain solution that is compatible with different assets and data protocols on each chain, allowing assets on different chains to support Cross - chain transfer and exchange.",
}

let name = 'Application'

export {
    name, en, zh
}