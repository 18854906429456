let en = {
  Roadmap:"Roadmap",
  content2021_1: "Open-SO 1.0 Released",
  content2021_2: "Seal Oracle Project Start",
  content2021_3: "Open-SO Upgrade to 2.0",
  content2021_4: "Open-SO Developer platform release",
  content2021_5: "Seal Oracle alpha Release",
  content2021_6: "Smart contract brigade Agreement to Release",
  content2021_7: "Seal Oracle beta Version Release",
  content2022_1: "Seal Oracle blockchain Agreement to Release",
  content2022_2: "Seal Oracle gateway Agreement to Release",
  content2022_3: "Seal Oracle Network Upgrade to Oracle Network",
  content2022_4: "Seal Oracle Test Network 'LINE' Release",
  content2022_5: "Seal Oracle common smart contract project startup",
  content2022_6: "Seal Oracle Test Network 'SURFACE' Release",
  Future:"Future",
  Future_1:"Seal Oracle Main Network 'SPACE' Release",
  Future_2:"Seal-Oracle Developer Platform Release",
  Future_3:"Seal Oracle Protocol Family Upgrade",
  Future_4:"Seal-Oracle All business migrated to Main Network",
}

let zh = {
  Roadmap:"路线图",
  content2021_1: "OpenSO1.0发布",
  content2021_2: "Seal Oracle 项目启动",
  content2021_3: "Open-SO 升级到 2.0",
  content2021_4: "Open-SO 开发者平台发布",
  content2021_5: "Seal Oracle alpha发布",
  content2021_6: "智能合约团队协议发布",
  content2021_7: "Seal Oracle 测试版发布",
  content2022_1: "Seal Oracle 区块链协议发布",
  content2022_2: "Seal Oracle 发布网关协议",
  content2022_3: "Seal Oracle Network 升级为 Oracle Network",
  content2022_4: "Seal Oracle 测试网络“LINE”发布",
  content2022_5: "Seal Oracle 通用智能合约项目启动",
  content2022_6: "Seal Oracle 测试网络'SURFACE'发布",
  Future:"未来规划",
  Future_1:"Seal Oracle 发布'SPACE'主网",
  Future_2:"Seal-Oracle 开发者平台发布",
  Future_1:"Seal Oracle 协议系列升级",
  Future_1:"Seal-Oracle 全部业务迁移到主网",
}

let name = 'Roadmap'

export {
  name, en, zh
}